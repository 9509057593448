import type {SuggestedUser, User, UserFollower, UserPersonalInfo, UserReferralResponse, UserStatistics} from '~/types/user'
import type {PaginatedPost} from '~/types/post'
import type {TableHeaderSort} from '~/types/table'
import type {AdminUserResults, AdminUserStatistics} from '~/types/admin'
import {useFetchResource} from '~/composables/fetch/useFetchResource'

const API_VERSION = 'v2'

const USER_ENDPOINT_ROOT = `user/${API_VERSION}`

export const useGetUserById = async (id: string) => {
  return useFetchResource<User>({
    url: () => `${USER_ENDPOINT_ROOT}/${id}`,
    method: 'get',
    isPublic: true
  })
}

export const useGetUserStatisticsById = async (id: string) => {
  return useFetchResource<UserStatistics>({
    url: () => `${USER_ENDPOINT_ROOT}/${id}/statistics`,
    method: 'get'
  })
}

export const useGetUserPosts = async (id: string, pageNumber: number) => {
  return useFetchResource<PaginatedPost>({
    url: () => `${USER_ENDPOINT_ROOT}/${id}/post`,
    method: 'get',
    query: {pageNumber}
  })
}

export const useDeleteUser = async (id: string) => {
  return useFetchResource<any>({
    url: () => `${USER_ENDPOINT_ROOT}/${id}`,
    method: 'delete'
  })
}

export const useCheckUserName = async (username: string) => {
  return useFetchResource<any>({
    method: 'get',
    url: () => `${USER_ENDPOINT_ROOT}/check-username?username=${username}`,
    isPublic: true
  })
}

export const useUserUpdatePassword = async (currentPassword: string, newPassword: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-password`,
    method: 'post',
    body: {currentPassword, newPassword}
  })
}

export const useUserSetType = async (type: string[]) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-type`,
    method: 'post',
    body: {type}
  })
}

export const useUserUpdatePersonalInfo = async (info: Partial<UserPersonalInfo>) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-info`,
    method: 'post',
    body: {...info}
  })
}

export const useUserUpdateEmailNotifications = async (info: any) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-email-notifications`,
    method: 'post',
    body: {...info}
  })
}

export const useUserUpdateAvatar = async (avatar: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/avatar`,
    method: 'post',
    body: {avatar}
  })
}

export const useGetUserFollowers = async (isFollowers: boolean, searchText: string) => {
  return useFetchResource<UserFollower>({
    url: () => `${USER_ENDPOINT_ROOT}/followers`,
    method: 'get',
    query: {isFollowers, searchText}
  })
}

export const useGetUserIsFollowingUser = async (userId: string) => {
  return useFetchResource<UserFollower>({
    url: () => `${USER_ENDPOINT_ROOT}/is-following`,
    method: 'get',
    query: {userId}
  })
}

export const useUserUpdateFollower = async (userId: string, follow: boolean) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/follow`,
    method: 'post',
    body: {userId, follow}
  })
}

export const useUserUpdatePrivacy = async (isPrivate: boolean) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-privacy`,
    method: 'post',
    body: {isPrivate}
  })
}

export const useGetUserArticles = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/articles`,
    method: 'get'
  })
}

export const useGetUserEvents = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/events`,
    method: 'get'
  })
}

export const useGetUserProjects = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/projects`,
    method: 'get'
  })
}

export const useGetUserTokens = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/tokens`,
    method: 'get'
  })
}

export const useGetUsersFiltered = async (params: any, pageSize: number, pageNumber: number, sortBy: TableHeaderSort) => {
  let filter = `sortBy=${sortBy.param}&sortAsc=${sortBy.asc}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  if (params.text) {
    filter += `&text=${params.text}`
  }
  if (params.status) {
    filter += `&status=${params.status}`
  }
  return useFetchResource<AdminUserResults>({
    url: () => `${USER_ENDPOINT_ROOT}/filtered?${filter}`,
    method: 'get'
  })
}

export const useUsersCountByStatus = async () => {
  return useFetchResource<AdminUserStatistics>({
    url: `${USER_ENDPOINT_ROOT}/count-status`,
    method: 'get'
  })
}

export const useUserUpdatePreferredLanguage = async (languageId: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/update-preferred-language`,
    method: 'post',
    body: {id: languageId}
  })
}

export const useUnsubscribeUserWeeklyEmails = async (email: string, reasons: string[], text: string | null) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/unsubscribe-weekly-email`,
    method: 'post',
    body: {email, reasons, text}
  })
}

export const useSubscribeUserWeeklyEmails = async (email: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/subscribe-weekly-email`,
    method: 'post',
    body: {email}
  })
}

export const useGetUserNameFromReferralCode = async (code: string) => {
  return useFetchResource<any>({
    url: () => `${USER_ENDPOINT_ROOT}/name-from-referral?code=${code}`,
    method: 'get'
  })
}

export const useBlockUser = async (id: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/block-user`,
    method: 'post',
    body: {id}
  })
}

export const useUnblockUser = async (id: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/unblock-user`,
    method: 'post',
    body: {id}
  })
}

export const useGetBlockedUsers = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/my-blocked-users`,
    method: 'get'
  })
}

export const useGetReportItemsByUser = async (id: string) => {
  return useFetchResource<any>({
    url: () => `${USER_ENDPOINT_ROOT}/report-items?id=${id}`,
    method: 'get'
  })
}

export const useAdminBlockUser = async (id: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/admin-block-user`,
    method: 'post',
    body: {id}
  })
}

export const useAdminUnblockUser = async (id: string) => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/admin-unblock-user`,
    method: 'post',
    body: {id}
  })
}

export const useGetUserReferralsByCode = async (referCode: string) => {
  return useFetchResource<UserReferralResponse[]>({
    url: () => `${USER_ENDPOINT_ROOT}/referrals?referCode=${referCode}`,
    method: 'get'
  })
}

export const useSuggestedUsers = async (size: number = 3) => {
  return useFetchResource<SuggestedUser[]>({
    url: () => `${USER_ENDPOINT_ROOT}/suggested-users?size=${size}`,
    method: 'get'
  })
}

export const useGetUserCommunities = async () => {
  return useFetchResource<any>({
    url: `${USER_ENDPOINT_ROOT}/communities`,
    method: 'get'
  })
}
